(function($, window, document, app) {
  $(window).on('load', function(){
    initMap();
  });

  window.app = app;

  /*
   * Global function for maps
   */
  window.initMap = function() {
    if ($('.map').length) {
      app.startSimpleMaps();
    }

    if ($('.map-clusters').length) {
      app.startClusterMap();
    }
  }

  /*
   * Start the app
   */
  app.init = function() {
    app.$win      = $(window);
    app.$doc      = $(document);
    app.$wrapper  = $('.wrapper');
    app.$footer   = $('.footer');
    app.mapStyles = [{"featureType":"administrative","elementType":"all","stylers":[{"saturation":"-100"}]},{"featureType":"administrative.province","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"landscape","elementType":"all","stylers":[{"saturation":-100},{"lightness":65},{"visibility":"on"}]},{"featureType":"poi","elementType":"all","stylers":[{"saturation":-100},{"lightness":"50"},{"visibility":"simplified"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":"-100"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"all","stylers":[{"lightness":"30"}]},{"featureType":"road.local","elementType":"all","stylers":[{"lightness":"40"}]},{"featureType":"transit","elementType":"all","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"water","elementType":"geometry","stylers":[{"hue":"#ffff00"},{"lightness":-25},{"saturation":-97}]},{"featureType":"water","elementType":"labels","stylers":[{"lightness":-25},{"saturation":-100}]}];
    app.sliders   = [
      {
        selector: '.slider .slider__slides',
        options : {
          slidesToShow: 1,
          draggable   : false,
          autoplay: true
        }
      }
    ];

    app.startCustomScrollbar();
    app.assignDynamicVars();
    app.startToggles();
    app.startSliders();
    app.hasDropdown();
    app.touchHover();
    app.pushFooter();
    app.hideError();
    app.parallax();
    app.fixForms();
    app.animate();
    app.count();

    app
      .$win
        .on('resize', app.assignDynamicVars);
  };

  /*
   * Touch hover
   */
  app.touchHover = function() {
    var activeClass = 'is-open';

    $('.nav a').on('touchstart', function(e){
      var $this       = $(this);

      if ($this.siblings().length && !$this.parent().hasClass(activeClass)) {
        e.preventDefault();

        $this
          .parent()
            .addClass(activeClass);
      }
    });

    app
      .$doc
        .on('touchstart', function(e) {
          var $target = $(e.target);

          if (!$target.closest('.nav').length) {
            $('.' + activeClass).removeClass(activeClass);
          }
        });
  };

  /*
   * Hide error
   */
  app.hideError = function() {
    app
      .$doc
        .on('focus', 'input', function(){
          var $error = $(this).parent().siblings('.gfield_description');

          if ($error.length) {
            $error.css({
              'display': 'none'
            });
          }
        })
        .on('blur', 'input', function(){
          var $error = $(this).parent().siblings('.gfield_description');

          if ($error.length) {
            $error.css({
              'display': 'block'
            });
          }
        });
  };

  /*
   * Fix Forms
   */
  app.fixForms = function() {
    var $formWrapper = $('.gform_wrapper');

    if ($formWrapper.length && !$formWrapper.closest('.form, .subscribe').length) {
      $formWrapper.addClass('no-form');
    }
  };

  /*
   * Start the custom scrollbar
   */
  app.startCustomScrollbar = function() {
    app
      .$doc
        .on('gform_post_render', function(){
          setTimeout(function() {
            $('.js-custom-scrollbar').mCustomScrollbar({
              scrollInertia: 200
            });
          }, 100);
        });
  };

  /*
   * Start all simple maps
   */
  app.startSimpleMaps = function() {
    $('.map').each(function(){
      var $map   = $(this);
      var center = {
        lat: $map.data('lat'),
        lng: $map.data('lng')
      }
      var map;

      map = new google.maps.Map(this, {
        center: center,
        zoom  : 10,
        styles: app.mapStyles
      });

      new google.maps.Marker({
        map     : map,
        position: center,
        icon    : crb.pin
      });
    });
  };

  function isMobile() {
    try{ document.createEvent("TouchEvent"); return true; }
    catch(e){ return false; }
  }

  /*
   * Start cluster map
   */
  app.startClusterMap = function() {
    var $map        = $('.map-clusters');
    var markers     = [];
    var infowindows = [];
    var template    = $('#infowindow-template').html();
    var map;

    google.maps.InfoWindow.prototype.isOpen = function(){
        var map = this.getMap();
        return (map !== null && typeof map !== "undefined");
    };

    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      map = new google.maps.Map($map.get(0), {
        center: crb.mapdata.center,
        zoom  : 3.5,
        styles: app.mapStyles
      });
    } else {
      map = new google.maps.Map($map.get(0), {
        center: crb.mapdata.center,
        zoom  : parseInt(crb.mapdata.zoom, 10),
        styles: app.mapStyles
      });
    }

    crb.mapdata.locations.forEach(function(markerData) {
      var marker = new google.maps.Marker({
        map     : map,
        position: markerData.center,
        icon    : crb.pin_small
      });

      if (markerData.infowindow) {
        var infowindow;

        markerData.infowindow.image = markerData.infowindow.image || '';

        marker.content = app.templateManager(template, markerData.infowindow);

        infowindow = new google.maps.InfoWindow({
          content: marker.content
        });

        marker.addListener('click', function(){
          infowindows.forEach(function(_infowindow){
            if (_infowindow !== infowindow) {
              _infowindow.close();
            }
          });

          if (infowindow.isOpen()) {
            infowindow.close();
          } else {
            infowindow.open(map, marker);
          }

          $('.gm-style-iw')
            .parent()
              .addClass('infowindow');
        });

        infowindows.push(infowindow);
      }

      markers.push(marker);
    });

    app.createClusters(map, markers);
  };

  /**
   * [ Manage templates ]
   *
   * @param  { String } template
   * @param  { Object } data
   *
   * @return { String }
   */
  app.templateManager = function(template, data) {
    console.log(data)
    while (template.indexOf('{{') > -1) {
      var stringToReplace = template.slice(template.indexOf('{{'), template.indexOf('}}') + 2);

      template = template.replace(stringToReplace, eval(stringToReplace.slice(2, stringToReplace.length - 2)));
    };

    return template;
  };


  /**
   * [ Create the clusters ]
   *
   * @param  { Google Maps Map } map
   * @param  { Array } markers
   *
   * @return { Void }
   */
  app.createClusters = function(map, markers) {
    new MarkerClusterer(map, markers, {
      imagePath: crb.cluster
    });
  }

  /*
   * Re-assign dynamic variables
   */
  app.assignDynamicVars = function() {
    app.windowHeight = app.$win.outerHeight();
  };

  /*
   * Push footer to the bottom of the page
   */
  app.pushFooter = function() {
    app
      .$win
        .on('load resize', function() {
          app
            .$wrapper
              .css({
                'paddingBottom': app.$footer.outerHeight() + 'px'
              });
        });
  };

  /*
   * Add a class to all navigation items with dropdown
   */
  app.hasDropdown = function() {
    $('.nav ul ul')
      .parent()
        .addClass('has-dd');

    $('.nav a').on('click', function(e){
      var $dropdown   = $(this).siblings('ul');
      var activeClass = 'is-visible';

      if (app.isMobile() && $dropdown.length && !$dropdown.hasClass(activeClass)) {
        e.preventDefault();

        $dropdown.addClass(activeClass);
      }
    });
  };

  /*
   * Check if screen size is mobile
   */
  app.isMobile = function() {
    return app.$win.outerWidth() < 768;
  };

  /*
   * Parallax
   */
  app.parallax = function() {
    var $image = $('.intro__background');

    app
      .$win
        .on('load scroll', function(){
          $image.css({
            'transform': 'translate(0, ' + (.3 * app.$win.scrollTop()) + 'px)'
          });
        });
  };

  /*
   * Animate elements
   */
  app.animate = function() {
    var $animated = $('.animate');
    var $wrapper  = $('.wrapper');

    app
      .$win
        .on('load scroll', function(){
          var winST = app.$win.scrollTop();

          $animated.each(function(){
            var $element = $(this);

            if (app.isInVP($element, winST)) {
              $element.addClass('animated');
            }
          });
        });
  };

  /*
   * Count
   */
  app.count = function() {
    var $count       = $('[data-count]');
    var countedClass = 'counted';

    app
      .$win
        .on('load scroll', function(){
          var winST = app.$win.scrollTop();

          $count.each(function(){
            var $element = $(this);

            if (app.isInVP($element, winST) && !$element.hasClass(countedClass)) {
              $element.addClass(countedClass);

              var number = $element.data('count');
              var prefix = $element.data('prefix') || '';
              var suffix = $element.data('suffix') || '';

              $({ counter: 0 }).animate({
                counter: number
              }, {
                duration: 2000,
                step: function(now) {
                  $element.text(prefix + Math.ceil(parseInt(now)) + suffix);
                }
              })
            }
          });
        });
  };

  /*
   * Star the sliders
   */
  app.startSliders = function() {
    app
      .$win
        .on('load', function() {
          app
            .sliders
              .forEach(function(slider) {
                $(slider.selector).slick(slider.options);
              });
        });
  };

  /*
   * Start the toggles
   */
  app.startToggles = function() {
    $('.js-toggle').on('click', function(e){
      e.preventDefault();

      var $this       = $(this);
      var $target     = $this.data('target') ? $($this.data('target')) : $($this.attr('href'));
      var activeClass = $this.data('class') ? $this.data('class') : 'active';

      $this
        .add($target)
        .toggleClass(activeClass);

      if ($this.data('dispatch')) {
        $target.trigger($this.data('dispatch'));
      }
    });

    app
      .$doc
        .on('click touchstart', function(e){
          var $target = $(e.target);

          $('[data-autoclose]').each(function(){
            var $this     = $(this);
            var element   = $this.data('inner-element') ? $this.data('inner-element') : $this.data('target') ? $this.data('target') : $this.attr('href');
            var className = $this.data('class') ? $this.data('class') : 'active';

            hideElementsOnClick($target, element, className);
          });
        });
  };

  /**
   * [ Check if element is in viewport ]
   *
   * @param  { jQuery Object }  $element
   * @param  { Integer }  winST
   *
   * @return { Boolean }
   */
  app.isInVP = function($element, winST) {
    return (winST + app.windowHeight / 1.05 > $element.offset().top - 60) || (winST + app.windowHeight - 60) > app.$wrapper.outerHeight();
  };

  /**
   * [hideElementsOnClick description]
   *
   * @param  { jQuery Object } $target      [ Target of click event ]
   * @param  { String } element           [ Classname of the element taht should be clicked in ]
   * @param  { String } className         [ The class to be removed ]
   *
   * @return { void }
   */
  app.hideElementsOnClick = function($target, element, className) {
    if (!$target.is(element + ', ' + element + ' *, .js-toggle.' + className + ', .js-toggle.' + className + ' *')) {
      $('.' + className).removeClass(className);
    }
  };
})(jQuery, window, document, window.app || {});

(function(app) {
  app.init();
})(window.app || {});
